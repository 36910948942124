import axios from 'axios'
export const url_backend = process.env.REACT_APP_API_URL

export const auth = {
    login: function (user, pass) {
        const data = {
            email: user,
            password: pass
        }
        return axios.post(url_backend + `/api/auth/login`, data)
    },
    cambiaPass: function (user, pass) {
        const data = {
            email: user,
            password: pass
        }
        return axios.post(url_backend + `/api/auth/cambiaPassPrimera`, data)
    },
    registro: function (data) {
        return axios.post(url_backend + `/api/auth/register`, data)
    },
    peticionRecuperacion: function (email) {
        const datos = {
            email: email
        }
        return axios.post(url_backend + `/api/auth/peticionRecuperacion`, datos)
    },
    recuperarPass: function (token, user, pass) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        const data = {
            email: user,
            password: pass
        }
        return axios.post(url_backend + `/api/auth/recuperarPass`, data)
    },
    logout: function (token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.post(url_backend + `/api/auth/logout`)
    },
    getProfile: function (token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.get(url_backend + `/api/auth/user-profile`)
    },
}

export const users = {
    getUsers: function(token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.get(url_backend + `/api/users/getUsers`)
    },
    getRoles: function(token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.get(url_backend + `/api/users/getRoles`)
    },
    editarAvatar: function(token, avatar, id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.post(url_backend + `/api/users/editAvatar/${id}`, {avatar: avatar})
    },
    editarUsuario: function(token, id, nombre, apellidos, email, departamento, rol) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        const datos = {
            name: nombre,
            apellidos: apellidos,
            email: email,
            departamento: departamento,
            rol: rol
        }
        return axios.post(url_backend + `/api/users/edit/${id}`, datos)
    },
    deleteUsuario: function(token, id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        return axios.post(url_backend + `/api/users/delete/${id}`)
    }
}

export const premios = {
    getPremio: function(token, id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.get(url_backend + `/api/premios/premio/${id}`)
    },
    getPremios: function(token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.get(url_backend + `/api/premios/premios`)
    },
    getPremiosMin: function(token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.get(url_backend + `/api/premios/premiosMin`)
    },
    getPremiosMisNominados: function(token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.get(url_backend + `/api/premios/PremiosMisNominados`)
    },
    getPremiosMisNominaciones: function(token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.get(url_backend + `/api/premios/PremiosMisNominaciones`)
    },
    editPremio: function(token, id, name, preguntas) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        const datos = {
            name: name,
            preguntas: preguntas
        }
        return axios.post(url_backend + `/api/premios/edit/${id}`, datos)
    }
}

export const nominaciones = {
    ultimosGanadores: function(token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.get(url_backend + `/api/nominaciones/ultimosGanadores`)
    },
    getMisNominados: function(token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.get(url_backend + `/api/nominaciones/misNominaciones`)
    },
    getPendientes: function(token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.get(url_backend + `/api/nominaciones/nominacionesPendientes`)
    },
    getAprobadas: function(token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.get(url_backend + `/api/nominaciones/nominacionesAprobadas`)
    },
    nuevaNominacion: function(token, id, respuestas, texto, media, premioId) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        const datos = {
            nominadoId: id,
            respuestas: respuestas,
            comentario: texto,
            media: media,
            premioId: premioId
        }
        return axios.post(url_backend + `/api/nominaciones/save`, datos)
    },
    editarNominacion: function(token, id, respuestas, texto, media, editar) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        const datos = {
            respuestas: respuestas,
            comentario: texto,
            media: media,
            editada: editar
        }
        return axios.post(url_backend + `/api/nominaciones/edit/${id}`, datos)
    },
    rechazarNominacion: function(token, id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.post(url_backend + `/api/nominaciones/cancel/${id}`)
    },
    reclamarRecompensa: function(token, id, recompensa) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        const datos = {
            recompensa: recompensa
        }
        return axios.post(url_backend + `/api/nominaciones/reclamar/${id}`, datos)
    },
    aceptarRecompensa: function(token, id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.post(url_backend + `/api/nominaciones/aceptarRecompensa/${id}`)
    }
}

export const recompensas = {
    getRecompensas: function(token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.get(url_backend + `/api/recompensas/getRecompensas`)
    }
}

export const notificaciones = {
    getNotificaciones: function(token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.get(url_backend + `/api/notificaciones/getNotificaciones`)
    },
    leerNotificacion: function(token, id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.post(url_backend + `/api/notificaciones/leerNotificacion/${id}`)
    },
    leerNotificaciones: function(token, id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.post(url_backend + `/api/notificaciones/leerNotificaciones`)
    }
}
import { CronJob } from "cron"
import { notificaciones } from '../data/Data'

/**
 * Obtenemos las crecenciales y token necesarios para las llamadas
 */
let isRunning = false;
let user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : '';
let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '';
// Fin 
/**
 * * cronJobAlertas
 * Cada 1 min se va a hacer una consulta a las alertas pendientes si esta online para ver cuantas tenemos sin leer
 * para establecer un badge icon en alertas
 */
const cronJobAlertas = new CronJob("* * * * *", async () => {  //new CronJob("*/5 * * * * ", async () => { -> se ejecutar�a cada 5 minutos
    if (token === '' || token != sessionStorage.getItem('token')) {
        token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '';
    }
    if (!isRunning) {
        isRunning = true
        if (token !== '') {
            notificaciones.getNotificaciones(token).then(response => {
                const alertasNoReaded = response.data.filter(alerta => alerta.leido === 0)
                sessionStorage.setItem('alertsNoReaded', alertasNoReaded.length);
            })
            .catch(err => console.warn(err))
        }
        isRunning = false
    }
})
cronJobAlertas.start()
import React from 'react'
import './App.css';
import { setConfiguration } from 'react-grid-system';

import {
  Route,
  Routes,
  BrowserRouter as Router,
} from 'react-router-dom'

import PrivateRoutes from './PrivateRoutes'
import PublicRoutes from './PublicRoutes'

const LoginComponent = React.lazy(() => import('./paginas/Login'));
const CambiaPassComponent = React.lazy(() => import('./paginas/CambiarPass'));
const RecuperarPassComponent = React.lazy(() => import('./paginas/RecuperarPass'));
const InicioComponent = React.lazy(() => import('./paginas/new/Inicio'));
const MiCuentaComponent = React.lazy(() => import('./paginas/new/MiCuenta'));
const NominacionesComponent = React.lazy(() => import('./paginas/new/Nominaciones'));
const AdministracionComponent = React.lazy(() => import('./paginas/new/Administracion'));
const MisReconocimientosComponent = React.lazy(() => import('./paginas/new/MisReconocimientos'));
const MisNominacionesComponent = React.lazy(() => import('./paginas/new/MisNominaciones'));

setConfiguration({ breakpoints: [600, 900, 1200, 1536, 1920] });

function App() {

  return (
    <>
      <Router>
        <React.Suspense fallback={'Cargando...'}>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route exact path='/cuenta' element={<MiCuentaComponent />} />
              <Route exact path='/cuenta/reconocimientos' element={<MisReconocimientosComponent />} />
              <Route exact path='/cuenta/nominaciones' element={<MisNominacionesComponent />} />
              <Route exact path='/nominaciones' element={<NominacionesComponent />} />
              <Route exact path='/administracion' element={<AdministracionComponent />} />
              <Route exact path='/administracion/nominaciones' element={<AdministracionComponent />} />
              <Route exact path='/administracion/reconocimientos' element={<AdministracionComponent />} />
              <Route exact path='/administracion/usuarios' element={<AdministracionComponent />} />
              <Route exact path='/administracion/resultados' element={<AdministracionComponent />} />
              <Route exact path='/' element={<InicioComponent />} />
            </Route>
            <Route element={<PublicRoutes />}>
              <Route exact path="/login" name="Login" element={<LoginComponent />} />
              <Route exact path="/cambiaPass" name="Cambiar Pass" element={<CambiaPassComponent />} />
              <Route exact path="/recuperarPass" name="Recuperar Pass" element={<RecuperarPassComponent />} />
            </Route>
          </Routes>
        </React.Suspense>
      </Router>
    </>
  );
}

export default App;